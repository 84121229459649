import React, { useMemo } from "react";
import ReactDOM from "react-dom";
import styles from "./InfoWindow.module.scss";
import { Button } from "components/shared/Button/Button";
import { Close } from "assets/icons/Close";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";

type TErrorWindow = {
    errorMessage: string;
    isOpened: boolean;
    setIsShown: (_: boolean) => void;
    type: string;
};

const InfoWindow = (props: TErrorWindow): JSX.Element => {
    const { errorMessage, isOpened, setIsShown, type } = props;

    const { t } = useTranslation();

    if (!isOpened) {
        return <></>;
    }

    const closeError = () => {
        setIsShown(false);
    };

    const infoMessageTitle = useMemo(() => {
        return (type && `${type}!`) || "";
    }, [type]);

    return ReactDOM.createPortal(
        <>
            <div className={styles.InfoMessageWindowBg} onClick={closeError}></div>

            <OutsideClickHandler onOutsideClick={closeError}>
                <div className={styles.InfoMessageWindow}>
                    <div className={styles.InfoMessageBody}>
                        <div className={styles.InfoMessageClose} onClick={closeError}>
                            <Close color={"#2B65AC"} />
                        </div>
                        <p className={styles.InfoMessageTitle}>
                            {infoMessageTitle}
                        </p>

                        {(type === "error") &&
                            <div className={styles.InfoMessageImage}>
                                <img src="/images/warning.svg" alt="Logo" />
                            </div>
                        }

                        <p className={styles.InfoMessageDescription}>
                            {errorMessage}
                        </p>
                        <div className={styles.InfoMessageButton}>
                            <Button variant="secondary" onClick={closeError}>
                                { t("faqPage.cancel") }
                            </Button>
                        </div>
                    </div>
                </div>
            </OutsideClickHandler>
        </>,
        document.body
    );
};

export default InfoWindow;
