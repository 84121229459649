import { useQuery, UseQueryResult } from "react-query";
import { getAccountHistory } from "../../../services/api/accountService";
import { extractErrorMessage } from "../../../utils";
import { useContext } from "react";
import { InfoContext } from "../../../contexts";
import { TAccountHistory } from "services/api/accountService";
import { TChainId } from "services/api/declarations";

export const useAccountHistoryQuery = (
    account: string | null | undefined,
    chainId: number | undefined,
    isAuthorizedWithWeb3: boolean | undefined
): UseQueryResult<TAccountHistory | null> => {
    const { showErrorMessage } = useContext(InfoContext);

    return useQuery<TAccountHistory | null>(
        ["accountQueryHistory", chainId, account, isAuthorizedWithWeb3],
        async() => {
            if (!chainId || !account || !isAuthorizedWithWeb3) {
                return null;
            }

            try {
                return await getAccountHistory(String(chainId) as TChainId, account);
            } catch (err) {
                showErrorMessage(extractErrorMessage(err));
                return null;
            }
        },
        {
            enabled: Boolean(chainId && account && isAuthorizedWithWeb3),
            refetchOnWindowFocus: false
        }
    );
};
