import React, { useEffect } from "react";
import { Web3WalletProvider } from "cnp-frontend-core";
import { Routers } from "./common/Routers/Routers";
import { pages } from "./pages";
import { AccountContextProvider, InfoContextProvider } from "contexts";
import { getEnvConfig } from "env/getEnvConfig";
import { QueryClient, QueryClientProvider } from "react-query";
import { CPCReadiness } from "./common/CPCReadiness/CPCReadiness";
import { useTranslation } from "react-i18next";
import { Api } from "services/CPCApi";

const env = getEnvConfig();

const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: true } } });

export const App = (): JSX.Element => {
    const { i18n } = useTranslation();

    useEffect(() => {
        let mounted = true;

        if (!mounted || !i18n) {
            return;
        }

        const language =
            localStorage.getItem("selectedLanguage") ||
            window.navigator.language;

        const locale = language.length >= 5
            ? language.substring(0, 2).toLowerCase()
            : language;

        i18n.changeLanguage(
            ["es", "en"].includes(locale) ? locale : "en"
        );

        return () => {
            mounted = false;
        };
    }, [i18n]);

    return (
        <Web3WalletProvider
            env={env}
            contracts={{}}
            api={Api}
        >
            <QueryClientProvider client={queryClient}>
                <AccountContextProvider>
                    <InfoContextProvider>
                        <CPCReadiness>
                            <Routers routes={pages}/>
                        </CPCReadiness>
                    </InfoContextProvider>
                </AccountContextProvider>
            </QueryClientProvider>
        </Web3WalletProvider>
    );
};
