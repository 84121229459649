import { Duration } from "luxon";
import { getYMDOfTimestamp } from "./staking";

export const ONE_CPC_MONTH_IN_DAYS = 30;
export const ONE_CPC_MONTH_IN_SECONDS = ONE_CPC_MONTH_IN_DAYS * 24 * 3600;

export const getFormatDateString = (
    endDateSeconds: number, formatString: "full" | "without_seconds" | "only_day"
) => {
    if (!endDateSeconds) return "";

    const endDateMillis = endDateSeconds * 1000;

    const now = Date.now();

    if (endDateMillis - now < 0) {
        return "";
    }

    const duration: Duration = Duration.fromMillis(endDateMillis - now);
    if (!duration.isValid) {
        return "";
    }

    if (formatString === "full") {
        const formatEnd = "d 'd' h 'h' m 'm' s 's'";
        return duration.toFormat(formatEnd);
    }
    if (formatString === "without_seconds") {
        const formatEnd = "d 'd' h 'h' m 'm'";
        return duration.toFormat(formatEnd);
    }
    if (formatString === "only_day") {
        const formatEnd = "d";
        return duration.toFormat(formatEnd);
    }
    return "";
};

export const getFormattedDateForChart = (timestamp: number, withYear?: boolean) => {
    const { y, m, d } = getYMDOfTimestamp(timestamp);

    return !withYear ? `${y - 2000}/${m + 1}/${d}` : `${m + 1}/${d}`;
};
