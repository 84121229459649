import { useQuery } from "react-query";
import { api } from "services/apiService";

export const useParametersFrontendQuery = () => {
    const fetchParameters = async() => {
        const { data } = await api.parameters.frontendList();

        return data;
    };

    return useQuery("parametersFrontendQuery", fetchParameters);
};
