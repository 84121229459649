import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Loader } from "../Loader/Loader";

export const RedirectToRootWithLoader = (): JSX.Element => {
    const history = useHistory();

    useEffect(() => {
        let mounted = true;

        const timer = setTimeout(() => {
            if (mounted) {
                history.push("/");
            }
        }, 2000);

        return () => {
            clearTimeout(timer);
            mounted = false;
        };
    }, [history]);

    return (
        <Loader />
    );
};
