export type TError = {
    message?: string;
    error?: { error?: string; message?: string; }
    details?: string;
};

const readErrorProperty = (error: TError): string => {
    return (
        (error && error.message) ||
		(error && error.error && error.error.message) ||
		(error && error.error && error.error.error) ||
		(error && error.error && error.details) ||
		"An error occurred, please try later"
    );
};

export const extractErrorMessage = (err: unknown): string => {
    return readErrorProperty(err as TError);
};

export const extractErrorMessageFromResponseObject = (err: unknown): string => {
    if (err && (err as Response | TError) instanceof Response) {
        return readErrorProperty(JSON.parse(JSON.stringify(err as Response)) as TError);
    } else {
        return readErrorProperty(err as TError);
    }
};