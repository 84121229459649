import React, { Context, createContext, ReactElement, useState } from "react";
import InfoWindow from "components/common/InfoWindow/InfoWindow";

export type UseErrorParams = {
    infoMessage?: string;
    showErrorMessage: (message?: string) => void;
    showWarningMessage: (message?: string) => void;
    showSuccessMessage: (message?: string) => void;
    clearMessage: () => void;
};

export const InfoContext: Context<UseErrorParams> = createContext({
    showErrorMessage: (_: string | undefined) => { },
    showWarningMessage: (_: string | undefined) => { },
    showSuccessMessage: (_: string | undefined) => { },
    clearMessage: () => { }
});

export function InfoContextProvider({ children }: {
    children: ReactElement;
}): JSX.Element {
    const [isShown, setIsShown] = useState(false);
    const [infoMessage, setInfoMessage] = useState<string>("");
    const [typeMessage, setTypeMessage] = useState<string>("");

    const showErrorMessage = (text?: string) => {
        setInfoMessage(text || "");
        setIsShown(true);
        setTypeMessage("error");
    };

    const showWarningMessage = (text?: string) => {
        setInfoMessage(text || "");
        setIsShown(true);
        setTypeMessage("warning");
    };

    const showSuccessMessage = (text?: string) => {
        setInfoMessage(text || "");
        setIsShown(true);
        setTypeMessage("success");
    };

    const clearMessage = () => {
        setInfoMessage("");
    };

    const params: UseErrorParams = {
        infoMessage,
        showErrorMessage,
        clearMessage,
        showWarningMessage,
        showSuccessMessage
    };

    return (
        <InfoContext.Provider value={params}>
            <div>
                {isShown && (
                    <InfoWindow
                        type={typeMessage}
                        errorMessage={infoMessage || ""}
                        isOpened={isShown}
                        setIsShown={setIsShown}
                    />
                )}
                {children}
            </div>
        </InfoContext.Provider>
    );
}

