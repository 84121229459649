import { useQuery, UseQueryResult } from "react-query";
import { getBalanceDetail, TBalanceDetails } from "../../../services/api/accountService";
import { extractErrorMessage } from "../../../utils";
import { useContext } from "react";
import { InfoContext } from "../../../contexts";
import { TChainId } from "services/api/declarations";

export const BALANCE_REQUEST_TIME_PERIOD_MS = 60 * 1000;

export const useAccountBalanceQuery = (
    account: string | null | undefined,
    chainId: number | undefined,
    isAuthorizedWithWeb3: boolean | undefined
): UseQueryResult<TBalanceDetails | null> => {
    const { showErrorMessage } = useContext(InfoContext);

    return useQuery<TBalanceDetails | null>(
        ["userBalance", account, chainId, isAuthorizedWithWeb3],
        async() => {
            if (!chainId || !account || !isAuthorizedWithWeb3) {
                return null;
            }

            try {
                const currentBalance = await getBalanceDetail(String(chainId) as TChainId, account);
                return currentBalance;
            } catch (err) {
                console.error(err);
                showErrorMessage(extractErrorMessage(err));
                return null;
            }
        },
        {
            enabled: Boolean(chainId && account && isAuthorizedWithWeb3),
            refetchOnWindowFocus: false,
            refetchInterval: BALANCE_REQUEST_TIME_PERIOD_MS
        }
    );
};
