import React from "react";

export type TErrorButton = {
    color?: "#2B65AC" | "#F8F9FA";
};

export const Close = ({ color }: TErrorButton): JSX.Element => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M2 2L20 20" stroke={color} strokeWidth="3" strokeLinecap="round"/>
            <path d="M20 2L2 20" stroke={color} strokeWidth="3" strokeLinecap="round"/>
        </svg>
    );
};


