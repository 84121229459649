export type TChartYears = {
    name: string,
    uv: number,
    fill: string
    year: number;
    month: number;
}

export const getYMDOfTimestamp = (unixTimestamp: number) => {
    const date = new Date(unixTimestamp * 1000);

    const y = date.getFullYear();
    const m = date.getMonth();
    const d = date.getDate();

    return { y, m, d };
};

const getDaysInMonth = (year: number, month: number) => {
    return new Date(year, month, 0).getDate();
};

type TIterateOverMonthsCallback = (value: number, year: number, month: number) => void;

const iterateOverMonths = (startTimestamp: number, endTimestamp: number, callback: TIterateOverMonthsCallback) => {
    const paramsAreInvalid = (
        !startTimestamp ||
        !endTimestamp ||
        isNaN(Number(startTimestamp)) ||
        isNaN(Number(endTimestamp)) ||
        !callback
    );

    if (paramsAreInvalid) {
        return;
    }

    const { y: startYear, m: startMonth, d: startDate } = getYMDOfTimestamp(startTimestamp);
    const { y: endYear, m: endMonth, d: endDate } = getYMDOfTimestamp(endTimestamp);
    // const { y: nowYear, m: nowMonth } = getYMDOfTimestamp(Date.now() / 1000);

    const firstMonthDays = getDaysInMonth(startYear, startMonth) - startDate;
    const lastMonthDays = getDaysInMonth(endYear, endMonth) - endDate;

    let tempYear = startYear;

    if (firstMonthDays > 0) {
        callback(firstMonthDays, startYear, startMonth);
    }

    let tempMonth = startMonth + 1;

    while ((tempYear < endYear) || (tempYear === endYear && tempMonth <= endMonth)) {
        if (tempYear === endYear && tempMonth === endMonth) {
            break;
        }
        if (tempMonth > 11) {
            tempYear += 1;
            tempMonth = 0;

            continue;
        }

        callback(getDaysInMonth(tempYear, tempMonth), tempYear, tempMonth);
        tempMonth += 1;
    }

    callback(lastMonthDays, endYear, endMonth);
};

export const composeChartYears = (startTimestamp: number, endTimestamp: number): TChartYears[] => {
    if (!startTimestamp || !endTimestamp || isNaN(Number(startTimestamp)) || isNaN(Number(endTimestamp))) {
        return [];
    }

    const { y: nowYear, m: nowMonth } = getYMDOfTimestamp(Date.now() / 1000);

    const intermediary: { name: string, uv: number, fill: string, year: number, month: number }[] = [];

    let acc = 0;
    let prevYear = 0;

    iterateOverMonths(startTimestamp, endTimestamp, (value, tempYear, tempMonth) => {
        acc = acc + value;

        if (prevYear === 0) {
            prevYear = tempYear;
        }

        if (prevYear < tempYear) {
            intermediary.push({
                name: String(tempYear),
                uv: acc,
                year: tempYear,
                month: tempMonth,
                fill: (tempYear < nowYear) || (tempYear <= nowYear && tempMonth <= nowMonth) ? "#2B65AC" : "#50B078"
            });
        }

        prevYear = tempYear;
    });

    if (intermediary.length === 0) {
        intermediary.push({
            name: String(prevYear),
            uv: acc,
            year: nowYear,
            month: nowMonth,
            fill: "#50B078"
        });
    }

    return intermediary;
};

export const composeChartMonths = (startTimestamp: number, endTimestamp: number): TChartYears[] => {
    if (!startTimestamp || !endTimestamp || isNaN(Number(startTimestamp)) || isNaN(Number(endTimestamp))) {
        return [];
    }

    const { y: nowYear, m: nowMonth } = getYMDOfTimestamp(Date.now() / 1000);

    const intermediary: {name: string, uv: number, fill: string, year: number, month: number }[] = [];

    let acc = 0;

    iterateOverMonths(startTimestamp, endTimestamp, (value, tempYear, tempMonth) => {
        acc = acc + value;

        intermediary.push({
            name: String(tempMonth),
            year: tempYear,
            month: tempMonth,
            uv: acc,
            fill: (tempYear < nowYear) || (tempYear <= nowYear && tempMonth <= nowMonth) ? "#2B65AC" : "#50B078"
        });
    });

    return intermediary;
};
