const NETWORK_NAME: Record<number, string> = {
    1: "Mainnet",
    3: "Ropsten",
    5: "Görli",
    100: "XDAI",
    137: "Polygon (Matic)",
    80001: "Mumbai (Matic testnet)"
};

export const gerNetworkName = (id: number) => {
    return NETWORK_NAME[id] || "unknown";
};
