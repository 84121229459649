import React from "react";
import styles from "./Loader.module.scss";

export const Loader = (): JSX.Element => {
    return (
        <div className={styles.Loding}>
            <div className={styles.LoadingCoin}>
                <div className={styles.LoadingBlock}>
                    <img
                        className={styles.LoadingImage}
                        src="/images/loading_image.png"
                        alt="loader"
                    />
                </div>
                <div className={styles.LoginShadow}></div>
            </div>
        </div>
    );
};