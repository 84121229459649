import { AccountContext } from "contexts";
import { BigNumber } from "ethers";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { BALANCE_REQUEST_TIME_PERIOD_MS } from "./useAccountBalanceQuery";

type TAccountLifeBalance = {
    lifeBalanceCurdsBigNum: BigNumber | undefined;
    isLiveBalanceNull: boolean;
};

const BALANCE_TIME_INTERVALS_COUNT_IN_PERIOD = 15;
const BALANCE_UPDATE_TIME_PERIOD_MS = 1 * 1000;
const BALANCE_UNIT_TIME_PERIOD = BALANCE_REQUEST_TIME_PERIOD_MS * BALANCE_TIME_INTERVALS_COUNT_IN_PERIOD;

export const useAccountLifeBalance = (): TAccountLifeBalance => {
    const { balanceCurds } = useContext(AccountContext);

    const [lifeBalanceCurdsBigNum, setLifeBalanceCurdsBigNum] = useState<BigNumber>(BigNumber.from(0));

    const balanceUpdateTimerId = useRef<number | null>(null);
    const balanceCurdsBackup = useRef<BigNumber>(BigNumber.from(0));

    const isLiveBalanceNull = useMemo(() => {
        return !lifeBalanceCurdsBigNum;
    }, [lifeBalanceCurdsBigNum]);

    useEffect(() => {
        let mounted = true;

        if (!mounted) {
            return;
        }

        if (!balanceCurds || balanceCurds.isZero()) {
            return;
        }

        if (balanceUpdateTimerId.current) {
            clearInterval(balanceUpdateTimerId.current);
        }

        if (balanceCurdsBackup.current.isZero()) {
            balanceCurdsBackup.current == balanceCurds;
        }

        const balanceRate = balanceCurds
            .sub(balanceCurdsBackup.current as BigNumber)
            .div(BALANCE_UNIT_TIME_PERIOD);

        setLifeBalanceCurdsBigNum(() => {
            return balanceCurds;
        });

        balanceUpdateTimerId.current = setInterval(() => {
            if (mounted) {
                setLifeBalanceCurdsBigNum((prevValue) => {
                    return prevValue.add(balanceRate);
                });
            }
        }, BALANCE_UPDATE_TIME_PERIOD_MS) as unknown as number;

        return () => {
            if (balanceUpdateTimerId.current) {
                clearInterval(balanceUpdateTimerId.current);
                balanceCurdsBackup.current == balanceCurds;
            }
            mounted = false;
        };
    }, [balanceCurds]);

    return {
        lifeBalanceCurdsBigNum,
        isLiveBalanceNull
    };
};
