/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface GenericError {
  code?: string;
  info?: object;
  message: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "https://ccc.customer.capacity.at/api";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title CPC API
 * @version 0.3.10
 * @baseUrl https://ccc.customer.capacity.at/api
 * @contact Capacity Blockchain Solutions GmbH <hello@capacity.at> (https://capacity.at/)
 *
 * API for the CPC Cheddr Coin project
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description You get better documentation by calling the /apidocs end point that delivers swagger UI.<br/>
   *
   * @name GetRoot
   * @summary Very basic root endpoint that just lists available endpoints.
   * @request GET:/
   * @response `200` `{ supported_urls?: ({ methods?: ("GET" | "POST" | "PUT" | "DELETE")[], url?: string })[] }` A list of endpoints
   * @response `500` `GenericError`
   */
  getRoot = (params: RequestParams = {}) =>
    this.request<
      { supported_urls?: { methods?: ("GET" | "POST" | "PUT" | "DELETE")[]; url?: string }[] },
      GenericError
    >({
      path: `/`,
      method: "GET",
      format: "json",
      ...params,
    });

  accounthistory = {
    /**
     * @description use event names as “event”:”transfer”,  “event”:”won_auction”<br/>
     *
     * @name AccounthistoryDetail
     * @summary History of transactions and other state changes for this asset
     * @request GET:/accounthistory/{chain}/{address}
     * @response `200` `({ balance_available_curds: string, balance_available_curds_change: string, balance_staked_curds: string, balance_staked_curds_change: string, chain_id: number, properties?: object, timestamp: number, type: "CoinTransfer" | "SproutingStart" | "SproutingEnd" | "StakingStart" | "StakingDropped" | "StakingFinished" | "balance" })[]` History of an account
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    accounthistoryDetail: (
      chain:
        | "default"
        | "eth"
        | "ethereum"
        | "ropsten"
        | "goerli"
        | "xdai"
        | "polygon"
        | "1"
        | "3"
        | "5"
        | "100"
        | "137",
      address: string,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          balance_available_curds: string;
          balance_available_curds_change: string;
          balance_staked_curds: string;
          balance_staked_curds_change: string;
          chain_id: number;
          properties?: object;
          timestamp: number;
          type:
            | "CoinTransfer"
            | "SproutingStart"
            | "SproutingEnd"
            | "StakingStart"
            | "StakingDropped"
            | "StakingFinished"
            | "balance";
        }[],
        GenericError
      >({
        path: `/accounthistory/${chain}/${address}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  asset = {
    /**
     * No description
     *
     * @name AssetDetail
     * @summary All public details of an asset that can be shown on its detail page
     * @request GET:/asset/{chain}/{symbol_or_address}/{token_id}
     * @response `200` `{ active_staking_info?: { duration_months?: number, faucet_percent?: number, start_timestamp?: number, total_return_curds?: string, total_yield_percent?: number }, asset_name?: string, chain_id: number, contract_address: string, contract_ensname?: string, contract_name: string, contract_symbol: string, fingerprint?: string, layer_network_name?: string, layer_network_symbol?: string, network_id?: string, network_name?: string, owner?: string, owner_chain_id?: number, owner_ens?: string, reveal_status?: "unrevealed" | "requested" | "successful", reveal_timestamp?: number | null, sprouting_timestamp?: number | null, staking_available?: boolean, token_id: number, token_image_path: string, total_number_issued?: number, uri?: string }` Info about an asset
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    assetDetail: (
      chain:
        | "default"
        | "eth"
        | "ethereum"
        | "ropsten"
        | "goerli"
        | "xdai"
        | "polygon"
        | "1"
        | "3"
        | "5"
        | "100"
        | "137",
      symbolOrAddress: string,
      tokenId: string,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          active_staking_info?: {
            duration_months?: number;
            faucet_percent?: number;
            start_timestamp?: number;
            total_return_curds?: string;
            total_yield_percent?: number;
          };
          asset_name?: string;
          chain_id: number;
          contract_address: string;
          contract_ensname?: string;
          contract_name: string;
          contract_symbol: string;
          fingerprint?: string;
          layer_network_name?: string;
          layer_network_symbol?: string;
          network_id?: string;
          network_name?: string;
          owner?: string;
          owner_chain_id?: number;
          owner_ens?: string;
          reveal_status?: "unrevealed" | "requested" | "successful";
          reveal_timestamp?: number | null;
          sprouting_timestamp?: number | null;
          staking_available?: boolean;
          token_id: number;
          token_image_path: string;
          total_number_issued?: number;
          uri?: string;
        },
        GenericError
      >({
        path: `/asset/${chain}/${symbolOrAddress}/${tokenId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  assethistory = {
    /**
     * @description use event names as “event”:”transfer”,  “event”:”won_auction”<br/>
     *
     * @name AssethistoryDetail
     * @summary History of transactions and other state changes for this asset
     * @request GET:/assethistory/{chain}/{symbol_or_address}/{token_id}
     * @response `200` `({ block: number, chain_id: number, new_owner?: string, new_owner_ens?: string, prev_owner?: string, prev_owner_ens?: string, timestamp: number, tx: string, type: "Transfer" })[]` History of an asset
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    assethistoryDetail: (
      chain:
        | "default"
        | "eth"
        | "ethereum"
        | "ropsten"
        | "goerli"
        | "xdai"
        | "polygon"
        | "1"
        | "3"
        | "5"
        | "100"
        | "137",
      symbolOrAddress: string,
      tokenId: string,
      query?: { show_future_entries?: boolean },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          block: number;
          chain_id: number;
          new_owner?: string;
          new_owner_ens?: string;
          prev_owner?: string;
          prev_owner_ens?: string;
          timestamp: number;
          tx: string;
          type: "Transfer";
        }[],
        GenericError
      >({
        path: `/assethistory/${chain}/${symbolOrAddress}/${tokenId}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  assignNft = {
    /**
     * No description
     *
     * @name AssignNftCreate
     * @summary Assign an NFT to a user.
     * @request POST:/assign_nft
     * @response `200` `{ account_address: string, chain_id?: number, token_address?: string, token_id: number, txhash_currency: string | null, txhash_faucet: string, txhash_transfer: string }` Information about the token that was assigned to that account.
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    assignNftCreate: (
      data: {
        reveal_timestamp: number;
        signature: string;
        signed_message: string;
        sprouting_timestamp?: number;
        target_address: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          account_address: string;
          chain_id?: number;
          token_address?: string;
          token_id: number;
          txhash_currency: string | null;
          txhash_faucet: string;
          txhash_transfer: string;
        },
        GenericError
      >({
        path: `/assign_nft`,
        method: "POST",
        body: data,
        type: ContentType.UrlEncoded,
        format: "json",
        ...params,
      }),
  };
  balance = {
    /**
     * No description
     *
     * @name BalanceDetail
     * @summary Balance of the native currency for this address
     * @request GET:/balance/{chain}/{address}
     * @response `200` `{ cheddar_curd_balance: string, wei_balance: string, wei_currency_symbol?: string }` Balances of an Ethereum account
     * @response `400` `GenericError`
     * @response `500` `GenericError`
     */
    balanceDetail: (
      chain:
        | "default"
        | "eth"
        | "ethereum"
        | "ropsten"
        | "goerli"
        | "xdai"
        | "polygon"
        | "1"
        | "3"
        | "5"
        | "100"
        | "137",
      address: string,
      params: RequestParams = {},
    ) =>
      this.request<{ cheddar_curd_balance: string; wei_balance: string; wei_currency_symbol?: string }, GenericError>({
        path: `/balance/${chain}/${address}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  broadcast = {
    /**
     * @description This is generic and takes any already-signed transaction.<br/>Use /txinfo/<chain>/<txhash> to track the status of the transaction after broadcasting.<br/>
     *
     * @name BroadcastCreate
     * @summary Broadcast a given signed transaction to the specified chain/network.
     * @request POST:/broadcast/{chain}
     * @response `200` `{ txhash?: string }` The tx hash of the broadcast transaction
     * @response `400` `GenericError`
     * @response `500` `GenericError`
     */
    broadcastCreate: (
      chain:
        | "default"
        | "eth"
        | "ethereum"
        | "ropsten"
        | "goerli"
        | "xdai"
        | "polygon"
        | "1"
        | "3"
        | "5"
        | "100"
        | "137",
      data: { tx: string },
      params: RequestParams = {},
    ) =>
      this.request<{ txhash?: string }, GenericError>({
        path: `/broadcast/${chain}`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),
  };
  cheddarcoinMint = {
    /**
     * No description
     *
     * @name CheddarcoinMintCreate
     * @summary Mint new CheddarCoins.
     * @request POST:/cheddarcoin_mint
     * @response `200` `{ txhash?: string }` Hash of the transaction triggered for this action
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    cheddarcoinMintCreate: (
      data: { amount_curds?: string; signature: string; signed_message: string; target_address?: string },
      params: RequestParams = {},
    ) =>
      this.request<{ txhash?: string }, GenericError>({
        path: `/cheddarcoin_mint`,
        method: "POST",
        body: data,
        type: ContentType.UrlEncoded,
        ...params,
      }),
  };
  contractInfo = {
    /**
     * @description This is a service for the UI so it does not have to have a separate copy of the JSON files.<br/>
     *
     * @name ContractInfoDetail
     * @summary Get contract infomation.
     * @request GET:/contract_info/{chain}/{contract_type}/{info_type}
     * @response `200` `(object | string | { abi?: object, address?: string, deploy_txhash?: string })` The requested info
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    contractInfoDetail: (
      chain:
        | "default"
        | "eth"
        | "ethereum"
        | "ropsten"
        | "goerli"
        | "xdai"
        | "polygon"
        | "1"
        | "3"
        | "5"
        | "100"
        | "137",
      contractType: "cheddardata" | "cheddar_coin" | "coin_faucet" | "cheddar_nft",
      infoType: "abi" | "address" | "instance",
      params: RequestParams = {},
    ) =>
      this.request<object | string | { abi?: object; address?: string; deploy_txhash?: string }, GenericError>({
        path: `/contract_info/${chain}/${contractType}/${infoType}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  invalidateCache = {
    /**
     * @description This will issue a command to invalidate a certain cache entry, as defined by the cache_name and parameters.<br/>
     *
     * @name InvalidateCacheCreate
     * @summary Invalidate a cache entry (for debugging use only).
     * @request POST:/invalidate_cache/{cache_name}
     * @response `204` `void` Empty response on success
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    invalidateCacheCreate: (
      cacheName: "config" | "keys" | "wallet_info" | "asset_list" | "asset_data" | "token_data",
      data: { parameter1?: string; parameter2?: string; parameter3?: string },
      params: RequestParams = {},
    ) =>
      this.request<void, GenericError>({
        path: `/invalidate_cache/${cacheName}`,
        method: "POST",
        body: data,
        type: ContentType.UrlEncoded,
        ...params,
      }),
  };
  meta = {
    /**
     * @description See https://eips.ethereum.org/EIPS/eip-721 and<br/>https://docs.rarebits.io/v1.0/docs/listing-your-assets#section-metadata for ERC 721,<br/>and https://eips.ethereum.org/EIPS/eip-1155#metadata for ERC 1155 tokens.<br/>Also, https://docs.opensea.io/docs/metadata-standards has some docs to look at for both.<br/>
     *
     * @name MetaDetail
     * @summary Metadata JSON that OpenSea and wallets can use to get images, etc. for the asset.
     * @request GET:/meta/{chain}/{symbol_or_address}/{token_id}
     * @response `200` `{ attributes?: ({ display_type?: string, trait_type?: string, value: string })[], description: string, external_url?: string, image?: string, name: string, tags?: (string)[] }` Metadata for an asset
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    metaDetail: (
      chain:
        | "default"
        | "eth"
        | "ethereum"
        | "ropsten"
        | "goerli"
        | "xdai"
        | "polygon"
        | "1"
        | "3"
        | "5"
        | "100"
        | "137",
      symbolOrAddress: string,
      tokenId: string,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          attributes?: { display_type?: string; trait_type?: string; value: string }[];
          description: string;
          external_url?: string;
          image?: string;
          name: string;
          tags?: string[];
        },
        GenericError
      >({
        path: `/meta/${chain}/${symbolOrAddress}/${tokenId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  parameters = {
    /**
     * No description
     *
     * @name FrontendList
     * @summary Parameters for the frontend. Note that this is cached.
     * @request GET:/parameters/frontend
     * @response `200` `{ "chain1": { addresses?: { cheddar_coin?: string, cheddar_nft?: string, cheddardata?: string, coin_faucet?: string }, chain_id: number, chain_parameters?: { blockExplorerUrls?: (string)[], chainId?: string, chainName?: string, nativeCurrency?: { decimals?: number, name?: string, symbol?: string }, rpcUrls?: (string)[] }, coin_symbol?: string, gasprice?: string, network_id: string, network_name: string, nft_symbol?: string }, nft_signedTransferWithOperator_selector?: string, nft_signedTransfer_selector?: string, signature_nonce_assign_nft?: number, signature_nonce_mint_cheddr?: number }` Parameters for the Frontend
     * @response `500` `GenericError`
     */
    frontendList: (params: RequestParams = {}) =>
      this.request<
        {
          chain1: {
            addresses?: { cheddar_coin?: string; cheddar_nft?: string; cheddardata?: string; coin_faucet?: string };
            chain_id: number;
            chain_parameters?: {
              blockExplorerUrls?: string[];
              chainId?: string;
              chainName?: string;
              nativeCurrency?: { decimals?: number; name?: string; symbol?: string };
              rpcUrls?: string[];
            };
            coin_symbol?: string;
            gasprice?: string;
            network_id: string;
            network_name: string;
            nft_symbol?: string;
          };
          nft_signedTransferWithOperator_selector?: string;
          nft_signedTransfer_selector?: string;
          signature_nonce_assign_nft?: number;
          signature_nonce_mint_cheddr?: number;
        },
        GenericError
      >({
        path: `/parameters/frontend`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description The nonce corresponds to the owner of the token who needs to do the transaction.<br/>
     *
     * @name TransferDetail
     * @summary Transaction parameters (nonce, gas requirements) to use for an asset transfer.
     * @request GET:/parameters/transfer/{chain}/{symbol_or_address}/{token_id}
     * @response `200` `{ contract_address: string, gaslimit: number, gasprice: string, nonce: number, signedTransferWithOperator_selector?: string, signedTransfer_selector?: string, signed_transfer_nonce?: number, signed_transfer_supported: boolean }` Metadata for an asset
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    transferDetail: (
      chain:
        | "default"
        | "eth"
        | "ethereum"
        | "ropsten"
        | "goerli"
        | "xdai"
        | "polygon"
        | "1"
        | "3"
        | "5"
        | "100"
        | "137",
      symbolOrAddress: string,
      tokenId: string,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          contract_address: string;
          gaslimit: number;
          gasprice: string;
          nonce: number;
          signedTransferWithOperator_selector?: string;
          signedTransfer_selector?: string;
          signed_transfer_nonce?: number;
          signed_transfer_supported: boolean;
        },
        GenericError
      >({
        path: `/parameters/transfer/${chain}/${symbolOrAddress}/${tokenId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  signedTransfer = {
    /**
     * @description The actions for this could be called by anyone but via this API, the system pays for the required gas.<br/>
     *
     * @name SignedTransferCreate
     * @summary Signed-transfer an NFT to a target.
     * @request POST:/signed_transfer/{chain}/{symbol_or_address}/{token_id}
     * @response `200` `{ txhash?: string }` Hash of the transaction triggered for this action
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    signedTransferCreate: (
      chain:
        | "default"
        | "eth"
        | "ethereum"
        | "ropsten"
        | "goerli"
        | "xdai"
        | "polygon"
        | "1"
        | "3"
        | "5"
        | "100"
        | "137",
      symbolOrAddress: string,
      tokenId: string,
      data: { signature: string; target_address: string },
      params: RequestParams = {},
    ) =>
      this.request<{ txhash?: string }, GenericError>({
        path: `/signed_transfer/${chain}/${symbolOrAddress}/${tokenId}`,
        method: "POST",
        body: data,
        type: ContentType.UrlEncoded,
        ...params,
      }),
  };
  startStaking = {
    /**
     * No description
     *
     * @name StartStakingCreate
     * @summary Start staking with an NFT.
     * @request POST:/start_staking/{chain}/{symbol_or_address}/{token_id}
     * @response `200` `{ txhash?: string }` Hash of the transaction triggered for this action
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    startStakingCreate: (
      chain:
        | "default"
        | "eth"
        | "ethereum"
        | "ropsten"
        | "goerli"
        | "xdai"
        | "polygon"
        | "1"
        | "3"
        | "5"
        | "100"
        | "137",
      symbolOrAddress: string,
      tokenId: string,
      data: { duration_months: number; faucet_percentage: number; signature: string; signed_message: string },
      params: RequestParams = {},
    ) =>
      this.request<{ txhash?: string }, GenericError>({
        path: `/start_staking/${chain}/${symbolOrAddress}/${tokenId}`,
        method: "POST",
        body: data,
        type: ContentType.UrlEncoded,
        ...params,
      }),
  };
  status = {
    /**
     * @description This is mainly used for monitoring.<br/>
     *
     * @name StatusList
     * @summary Get status information for the API.
     * @request GET:/status
     * @response `200` `{ api_version?: string, db_connections?: number | null, event_threads_expected?: number, event_threads_running?: number, "layer1"?: { addresses?: { cheddar_coin?: string, cheddar_nft?: string, cheddardata?: string, coin_faucet?: string }, chain_id?: number, last_chain_block?: number, last_handled_block?: number, network_id?: string, network_name?: string, "web3_connection"?: boolean }, memory_used_kb?: number, nft_supply?: number | null, timer_threads_expected?: number, timer_threads_running?: number, total_threads_running?: number, waiting_for_tx?: number }` A list of status data about the Signer
     * @response `500` `GenericError`
     */
    statusList: (params: RequestParams = {}) =>
      this.request<
        {
          api_version?: string;
          db_connections?: number | null;
          event_threads_expected?: number;
          event_threads_running?: number;
          layer1?: {
            addresses?: { cheddar_coin?: string; cheddar_nft?: string; cheddardata?: string; coin_faucet?: string };
            chain_id?: number;
            last_chain_block?: number;
            last_handled_block?: number;
            network_id?: string;
            network_name?: string;
            web3_connection?: boolean;
          };
          memory_used_kb?: number;
          nft_supply?: number | null;
          timer_threads_expected?: number;
          timer_threads_running?: number;
          total_threads_running?: number;
          waiting_for_tx?: number;
        },
        GenericError
      >({
        path: `/status`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  stopFaucet = {
    /**
     * @description WARNING: All staked CheddarCoins will be LOST when this is called!<br/>
     *
     * @name StopFaucetCreate
     * @summary Forcefully stop faucet (and staking!) for a certain topic - e.g. at end of employment.
     * @request POST:/stop_faucet/{chain}/{symbol_or_address}/{token_id}
     * @response `200` `{ txhash?: string }` Hash of the transaction triggered for this action
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    stopFaucetCreate: (
      chain:
        | "default"
        | "eth"
        | "ethereum"
        | "ropsten"
        | "goerli"
        | "xdai"
        | "polygon"
        | "1"
        | "3"
        | "5"
        | "100"
        | "137",
      symbolOrAddress: string,
      tokenId: string,
      data: { signature: string; signed_message: string },
      params: RequestParams = {},
    ) =>
      this.request<{ txhash?: string }, GenericError>({
        path: `/stop_faucet/${chain}/${symbolOrAddress}/${tokenId}`,
        method: "POST",
        body: data,
        type: ContentType.UrlEncoded,
        ...params,
      }),
  };
  triggerReveal = {
    /**
     * No description
     *
     * @name TriggerRevealCreate
     * @summary Assign an NFT to a user.
     * @request POST:/trigger_reveal/{chain}/{symbol_or_address}/{token_id}
     * @response `200` `{ token_uri?: string, txhash?: string }` New token URI assigned to the revealed token
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    triggerRevealCreate: (
      chain:
        | "default"
        | "eth"
        | "ethereum"
        | "ropsten"
        | "goerli"
        | "xdai"
        | "polygon"
        | "1"
        | "3"
        | "5"
        | "100"
        | "137",
      symbolOrAddress: string,
      tokenId: string,
      data: { signature: string; signed_message: string },
      params: RequestParams = {},
    ) =>
      this.request<{ token_uri?: string; txhash?: string }, GenericError>({
        path: `/trigger_reveal/${chain}/${symbolOrAddress}/${tokenId}`,
        method: "POST",
        body: data,
        type: ContentType.UrlEncoded,
        format: "json",
        ...params,
      }),
  };
  txinfo = {
    /**
     * @description This is generic and reports on any transaction, even not caring if it actually exists.<br/>
     *
     * @name TxinfoDetail
     * @summary Get the status/info of a transaction on the specified chain/network.
     * @request GET:/txinfo/{chain}/{txhash}
     * @response `200` `{ blockNumber?: number, error_message?: string, gasLimit?: number, gasUsed?: number, mined?: boolean, pending?: boolean, status?: number, successful?: boolean, transactionIndex?: number }` Information about this transaction
     * @response `400` `GenericError`
     * @response `500` `GenericError`
     */
    txinfoDetail: (
      chain:
        | "default"
        | "eth"
        | "ethereum"
        | "ropsten"
        | "goerli"
        | "xdai"
        | "polygon"
        | "1"
        | "3"
        | "5"
        | "100"
        | "137",
      txhash: string,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          blockNumber?: number;
          error_message?: string;
          gasLimit?: number;
          gasUsed?: number;
          mined?: boolean;
          pending?: boolean;
          status?: number;
          successful?: boolean;
          transactionIndex?: number;
        },
        GenericError
      >({
        path: `/txinfo/${chain}/${txhash}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  version = {
    /**
     * @description This is mainly used for debugging.<br/>
     *
     * @name VersionList
     * @summary Get version information for the API.
     * @request GET:/version
     * @response `200` `{ api?: string, cnp?: string, flasgger?: string, flask?: string, gevent?: string, python?: string, requests?: string, sqlalchemy?: string, "web3py"?: string, yaml?: string }` A list of status data about the Signer
     * @response `500` `GenericError`
     */
    versionList: (params: RequestParams = {}) =>
      this.request<
        {
          api?: string;
          cnp?: string;
          flasgger?: string;
          flask?: string;
          gevent?: string;
          python?: string;
          requests?: string;
          sqlalchemy?: string;
          web3py?: string;
          yaml?: string;
        },
        GenericError
      >({
        path: `/version`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  wallet = {
    /**
     * No description
     *
     * @name WalletDetail
     * @summary List of NFTs
     * @request GET:/wallet/{chain}/{address}
     * @response `200` `{ address: string, assets: ({ active_staking_info?: { duration_months?: number, faucet_percent?: number, start_timestamp?: number, total_return_curds?: string, total_yield_percent?: number }, chain_id: number, layer_network_symbol?: string, reveal_status?: "unrevealed" | "requested" | "successful", reveal_timestamp?: number, sprouting_timestamp?: number | null, staking_available?: boolean, token_address: string, token_id: number, token_symbol?: string, uri?: string })[], chain_id?: number, cheddar_curd_balance?: string, ensname?: string, messages?: (string)[], network_id: string, network_name: string }` Information about an Ethereum wallet
     * @response `400` `GenericError`
     * @response `500` `GenericError`
     */
    walletDetail: (
      chain:
        | "default"
        | "eth"
        | "ethereum"
        | "ropsten"
        | "goerli"
        | "xdai"
        | "polygon"
        | "1"
        | "3"
        | "5"
        | "100"
        | "137",
      address: string,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          address: string;
          assets: {
            active_staking_info?: {
              duration_months?: number;
              faucet_percent?: number;
              start_timestamp?: number;
              total_return_curds?: string;
              total_yield_percent?: number;
            };
            chain_id: number;
            layer_network_symbol?: string;
            reveal_status?: "unrevealed" | "requested" | "successful";
            reveal_timestamp?: number;
            sprouting_timestamp?: number | null;
            staking_available?: boolean;
            token_address: string;
            token_id: number;
            token_symbol?: string;
            uri?: string;
          }[];
          chain_id?: number;
          cheddar_curd_balance?: string;
          ensname?: string;
          messages?: string[];
          network_id: string;
          network_name: string;
        },
        GenericError
      >({
        path: `/wallet/${chain}/${address}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
