import { useContext, useEffect, useState } from "react";
import {
    AddEthereumChainParameter,
    Web3AuthFacadeContext
} from "cnp-frontend-core";
import goerli from "hooks/network/goerli.json";
import polygon from "hooks/network/polygon.json";
import mumbai from "hooks/network/mumbai.json";
import { InfoContext } from "contexts";
import { extractErrorMessage } from "utils";

const allNetworks: Record<number, AddEthereumChainParameter> = {
    5: goerli,
    137: polygon,
    80001: mumbai
};

const GOERLI_CHAIN_ID = 5;
const POLYGON_CHAIN_ID = 137;
// const MUMBAI_CHAIN_ID = 80001; // @FIXME:

const ADD_ETHEREUM_CHAIN_METHOD = "wallet_addEthereumChain";
const SWITCH_ETHEREUM_CHAIN_METHOD = "wallet_switchEthereumChain";

export type UseNetworkParams = {
	// networks: AddEthereumChainParameter[];
	activeNetwork: AddEthereumChainParameter | undefined;
	switchNetwork: (networkConfig: AddEthereumChainParameter) => void;
	switchToChainId: (chainId: number) => void;
	getNetworkName: (chainId: number) => string;
};

export const useNetwork = (): UseNetworkParams => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [provider, setProvider] = useState<any>();

    const {
        isAuthorizedWithWeb3,
        getProviderAsync,
        chainId
    } = useContext(Web3AuthFacadeContext);
    const { showErrorMessage } = useContext(InfoContext);

    useEffect(() => {
        let mounted = true;

        if (mounted && isAuthorizedWithWeb3) {
            (async() => {
                if (mounted) {
                    const prov = await getProviderAsync();
                    // @FIXME: an issue: when in cnp core we authorized with web3 (ethereum provider)
                    // but it is null
                    // reproduced for Coinbase Browser extention
                    if (!prov) {
                        const realProvider = window.ethereum;
                        setProvider(realProvider);
                    } else {
                        setProvider(prov);
                    }
                }
            })();
        }

        return () => {
            mounted = false;
        };
    }, [getProviderAsync, isAuthorizedWithWeb3]);

    const allNetworkChains = [
        allNetworks[POLYGON_CHAIN_ID],
        allNetworks[GOERLI_CHAIN_ID]
        // allNetworks[MUMBAI_CHAIN_ID],
    ];

    const switchNetwork = async(networkConfig: AddEthereumChainParameter) => {
        const chainIdNum = parseInt(networkConfig.chainId, 16);

        let params: AddEthereumChainParameter | { chainId: string };
        let method: string;

        // @FIXME: in order to make the switch-network working
        // we need to check chainIdNum to match the main used network (Polygon for that project)
        if (chainIdNum === POLYGON_CHAIN_ID) {
            method = ADD_ETHEREUM_CHAIN_METHOD;
            params = networkConfig;
        } else {
            method = SWITCH_ETHEREUM_CHAIN_METHOD;
            params = { chainId: networkConfig.chainId };
        }

        if (provider) {
            try {
                await provider.request({
                    id: 1,
                    jsonrpc: "2.0",
                    method: method,
                    params: [params]
                });
            } catch (switchError) {
                // This error code indicates that the chain has not been added to MetaMask.
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                if ((switchError as any).code === 4902) {
                    try {
                        await provider.request({
                            id: 1,
                            jsonrpc: "2.0",
                            method: ADD_ETHEREUM_CHAIN_METHOD,
                            params: [params]
                        });
                    } catch (addError) {
                        console.error(addError);
                        showErrorMessage(extractErrorMessage(addError));
                    }
                }
                console.error(switchError);
                showErrorMessage(extractErrorMessage(switchError));
            }
        } else {
            console.error("Fail network switching");
            showErrorMessage(extractErrorMessage("Fail network switching"));
        }
    };

    const switchToChainId = (chainId: number) => {
        const configuredChain = allNetworkChains.find(
            (chain) => {
                return parseInt(chain.chainId, 16) === chainId;
            }
        );

        if (!configuredChain) {
            showErrorMessage(`chain id ${chainId} not configured`);
            throw Error(`chain id ${chainId} not configured`);
        }

        switchNetwork(configuredChain);
    };

    const activeNetwork = chainId ? allNetworks[chainId] : undefined;

    const getNetworkName = (chainId: number) => {
        if (allNetworks && allNetworks[chainId].chainName) {
            return allNetworks[chainId].chainName as string;
        } else {
            return "";
        }
    };

    return {
        // networks: allNetworkChains,
        activeNetwork,
        switchNetwork,
        switchToChainId,
        getNetworkName
    };
};
