import React, { ReactNode } from "react";
import { useParametersFrontendQuery } from "hooks/query/parameters";
import { Loader } from "../Loader/Loader";

type TCPCReadiness = {
    children: ReactNode
};

export const CPCReadiness = ({ children }: TCPCReadiness): JSX.Element => {
    const { data } = useParametersFrontendQuery();
    return data ? <>{children}</> : <Loader />;
};
