import { api } from "services/apiService";
import { TChainId } from "./declarations";

export type TShowRevealLoopVideoStatus =
    | "idle"
    | "loop"
    | "finish";

export type TWalletDetails = ReturnedPromiseResolvedType<
    typeof getWalletDetails
>;
export type TWalletDetailsAssetItem = Unarray<TWalletDetails["assets"]>;

export type TAssetDetails = ReturnedPromiseResolvedType<
    typeof getAssetDetailsByTokenId
>;

export type TBalanceDetails = ReturnedPromiseResolvedType<
    typeof getBalanceDetail
>;

export type TAccountHistory = ReturnedPromiseResolvedType<
    typeof getAccountHistory
>;

export const getAssetDetailsByTokenId = async(
    chainId: TChainId,
    tokenSymbol: string,
    tokenId: string
) => {
    try {
        const result = await api.asset.assetDetail(
            chainId,
            tokenSymbol,
            tokenId
        );
        if (result.error) {
            throw result.error;
        }
        return result.data;
    } catch (err) {
        throw err;
    }
};

export const getWalletDetails = async(
    chainId: TChainId,
    address: string
) => {
    try {
        const result = await api.wallet.walletDetail(chainId, address);
        return result.data;
    } catch (err) {
        throw err;
    }
};

export const getBalanceDetail = async(
    chainId: TChainId,
    address: string
) => {
    try {
        const result = await api.balance.balanceDetail(chainId, address);
        return result.data;
    } catch (err) {
        throw err;
    }
};

export const getAccountHistory = async(
    chainId: TChainId,
    address: string
) => {
    try {
        const result = await api.accounthistory.accounthistoryDetail(
            chainId,
            address
        );
        return result.data;
    } catch (err) {
        throw err;
    }
};
