import { TExtendedConditionalRoute } from "components/common/Routers/Routers";
import React from "react";

export const pages: TExtendedConditionalRoute[] = [
    {
        path: "/",
        exact: true,
        withWeb3Auth: false,
        enableAutoRedirectToHome: true,
        component: React.lazy(() => {
            return import("components/pages/Intro/Intro");
        }),
        title: (): string => {
            return "cpc | Introduction";
        }
    },
    {
        path: "/start",
        exact: true,
        withWeb3Auth: true,
        title: (): string => {
            return "cpc | Reveal Token";
        },
        component: React.lazy(() => {
            return import("components/pages/StartPage/StartPage");
        })
    },
    {
        path: "/my-tokens",
        exact: true,
        withWeb3Auth: true,
        enableAutoRedirectToHome: true,
        title: (): string => {
            return "cpc | My NFT";
        },
        component: React.lazy(() => {
            return import("components/pages/TokenList/TokenList");
        })
    },
    {
        path: "/buy-on-opensea",
        exact: true,
        withWeb3Auth: true,
        enableAutoRedirectToHome: true,
        title: (): string => {
            return "cpc | Buy on OpenSea";
        },
        component: React.lazy(() => {
            return import("components/pages/BuyOnOpenSea/BuyOnOpenSea");
        })
    },
    {
        path: "/token/:tokenId",
        exact: true,
        enableAutoRedirectToHome: true,
        title: (): string => {
            return "cpc | Token page";
        },
        component: React.lazy(() => {
            return import("components/pages/TokenPage/TokenPage");
        })
    },
    {
        path: "/staking/:tokenId",
        exact: true,
        withWeb3Auth: true,
        enableAutoRedirectToHome: true,
        title: (): string => {
            return "cpc | Staking";
        },
        component: React.lazy(() => {
            return import("components/pages/Staking/Staking");
        })
    },
    {
        path: "/send-cheddr",
        exact: true,
        withWeb3Auth: true,
        title: (): string => {
            return "cpc | Send Cheddr";
        },
        component: React.lazy(() => {
            return import("components/pages/SendCheddar/SendCheddar");
        })
    },
    {
        path: "/version",
        exact: true,
        withWeb3Auth: false,
        component: React.lazy(() => {
            return import("components/pages/Version/Version");
        }),
        title: (): string => {
            return "Version";
        }
    },
    {
        path: "/loader",
        exact: true,
        withWeb3Auth: false,
        component: React.lazy(() => {
            return import("components/pages/Loading/Loading");
        }),
        title: (): string => {
            return "Loading";
        }
    },
    {
        path: "/faq",
        exact: true,
        withWeb3Auth: false,
        component: React.lazy(() => {
            return import("components/pages/FAQ/FAQ");
        }),
        title: (): string => {
            return "Loading";
        }
    },
    {
        path: "*",
        exact: true,
        withWeb3Auth: false,
        title: (): string => {
            return "cpc | Not found";
        },
        component: React.lazy(() => {
            return import("components/pages/NotFound/NotFound");
        })
    }
];
