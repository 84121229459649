import React, { Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { TConditionalRoute } from "cnp-frontend-core";
import { ProtectedRoute } from "./ProtectedRoute";
import { Loader } from "../Loader/Loader";
import { SplashScreenContextProvider } from "contexts";
import { DetectHomePageContextProvider } from "contexts/DetectHomePageContext";

export type TExtendedConditionalRoute = TConditionalRoute & {
    enableAutoRedirectToHome?: boolean;
};

type TRouters = {
    routes: TExtendedConditionalRoute[]
};

const renderRoute = (props: TExtendedConditionalRoute) => {
    if (!props) return null;

    if (props.withWeb3Auth) {
        return (
            <ProtectedRoute
                key={props.path}
                {...props}
                component={props.component}
                enableAutoRedirectToHome={props.enableAutoRedirectToHome}
            />
        );
    }

    return (
        <Route
            key={props.path}
            {...props}
            component={props.component}
        />
    );
};

export const Routers = ({ routes }: TRouters): JSX.Element => {
    return (
        <BrowserRouter>
            <SplashScreenContextProvider>
                <DetectHomePageContextProvider>
                    <Suspense
                        fallback={<Loader />}
                    >
                        <Switch>
                            {
                                routes
                                    .filter(Boolean)
                                    .map(renderRoute)
                            }
                        </Switch>
                    </Suspense>
                </DetectHomePageContextProvider>
            </SplashScreenContextProvider>
        </BrowserRouter>
    );
};