import { AuthFacadeContext, Web3AuthFacadeContext } from "cnp-frontend-core";
import { getEnvConfig } from "env/getEnvConfig";
import { getTokenDetailsAsync } from "hooks";
import React, { Context, createContext, ReactElement, useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import { TAssetDetails } from "services/api/accountService";
import { extractErrorMessage } from "utils";
import { AccountContext } from "./AccountContext";
import { InfoContext } from "./InfoBoxContext";
import { SplashScreenContext } from "./SplashScreenContext";

export type TDetectHomePageParams = {
    goHomePage: () => void;
    goToTokenInfoByTokenId: (tokenId: string) => void;
    goToMyTokens: () => void;
    isMyToken: (tokenId: string) => boolean;
    checkOrRedirectOnSameRoute: (route: string) => void;
    getHomePageRoute: () => Promise<string | null>;
};

export const DetectHomePageContext: Context<TDetectHomePageParams> = createContext({
    goHomePage: () => { },
    goToTokenInfoByTokenId: (_: string) => { },
    goToMyTokens: () => { },
    isMyToken: (_: string) => {
        return false as boolean;
    },
    checkOrRedirectOnSameRoute: (_: string) => {},
    getHomePageRoute: () => {
        return Promise.resolve(null as string | null);
    }
});

export const TOKEN_ID_KEY = "tokenId";

export function DetectHomePageContextProvider({ children }: {
    children: ReactElement;
}): JSX.Element {
    const env = getEnvConfig();

    const { redirectWithLoading } = useContext(SplashScreenContext);
    const { walletDetails } = useContext(AccountContext);

    const { showErrorMessage } = useContext(InfoContext);

    const history = useHistory();

    const checkOrRedirectOnSameRoute = (route: string): void => {
        if (!route) {
            return;
        }

        if (history.location.pathname.indexOf(route) !== -1) {
            return;
        }

        redirectWithLoading(route);
    };

    const { isAuthorizedWithWeb3 }: AuthFacadeContext = useContext(Web3AuthFacadeContext);

    const goToMyTokens = useCallback(() => {
        if (!isAuthorizedWithWeb3) {
            return;
        }

        const tokenList = walletDetails?.assets;
        if (!tokenList) {
            return;
        }

        if (tokenList.length > 0) {
            localStorage.removeItem(TOKEN_ID_KEY);
            return checkOrRedirectOnSameRoute("/my-tokens");
        }
        if (tokenList.length === 0) {
            return checkOrRedirectOnSameRoute("/buy-on-opensea");
        }
        return checkOrRedirectOnSameRoute("/start");
    }, [Boolean(walletDetails), isAuthorizedWithWeb3]);

    const isMyToken = useCallback((tokenId: string): boolean => {
        if (!tokenId || !walletDetails) {
            return false;
        }
        const foundToken = walletDetails.assets
            .find((token) => {
                return token.token_id === Number(tokenId);
            });
        return foundToken != null;
    }, [Boolean(walletDetails)]);

    const goToTokenInfoByTokenId = useCallback(async(tokenId: string) => {
        if (!isAuthorizedWithWeb3) {
            return;
        }

        const { defaultChainId } = getEnvConfig();

        let tokenInfo: TAssetDetails | undefined | null;

        try {
            tokenInfo = await getTokenDetailsAsync(
                tokenId,
                env.tokenSymbol,
                defaultChainId
            );
        } catch (err) {
            return showErrorMessage(extractErrorMessage(err));
        }

        checkOrRedirectOnSameRoute(`/token/${tokenInfo?.token_id}`);
    }, [isAuthorizedWithWeb3]);

    const getHomePageRoute = useCallback(async(): Promise<string | null> => {
        if (!isAuthorizedWithWeb3) {
            return null;
        }

        const tokenList = walletDetails?.assets;
        if (!tokenList) {
            return null;
        }

        if (tokenList.length === 0) {
            return "/buy-on-opensea";
        }

        let tokenId: string = localStorage.getItem(TOKEN_ID_KEY) || "";
        const isInvalidToken = (!tokenId || tokenId === "undefined" || isNaN(Number(tokenId)));

        if (isInvalidToken && tokenList.length > 1) {
            localStorage.removeItem(TOKEN_ID_KEY);
            return "/my-tokens";
        }

        if (isInvalidToken && tokenList.length === 1) {
            const firstInTheListTokenId = (
                walletDetails &&
                walletDetails.assets &&
                walletDetails.assets[0] &&
                walletDetails.assets[0].token_id
            );
            tokenId = (
                firstInTheListTokenId &&
                !isNaN(Number(firstInTheListTokenId)) &&
                String(firstInTheListTokenId)
            ) || "";
        }

        const { defaultChainId } = getEnvConfig();

        localStorage.setItem(TOKEN_ID_KEY, tokenId);

        // @FIXME: later check if we need to use the result of the response
        try {
            await getTokenDetailsAsync(
                tokenId,
                env.tokenSymbol,
                defaultChainId
            );
        } catch (err) {
            showErrorMessage(extractErrorMessage(err));
            return null;
        }

        return `/token/${tokenId}`;
    }, [isAuthorizedWithWeb3, Boolean(walletDetails)]);

    const goHomePage = async() => {
        const route = await getHomePageRoute();

        if (!route) {
            return;
        }

        return checkOrRedirectOnSameRoute(route);
    };

    const params: TDetectHomePageParams = {
        goHomePage,
        goToTokenInfoByTokenId,
        goToMyTokens,
        isMyToken,
        checkOrRedirectOnSameRoute,
        getHomePageRoute
    };

    return (
        <DetectHomePageContext.Provider value={params}>
            <div>
                {children}
            </div>
        </DetectHomePageContext.Provider>
    );
}

