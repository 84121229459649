import { BigNumber, ethers } from "ethers";

export const CHEDDDAR_TO_USD_RATE_FOR_1_CHEDDR = 5;
const DISPLAY_DECIMALS_CHEDDR = 6;

export const cheddrFormatters = (curds: BigNumber | undefined): string => {
    if (curds === undefined) {
        return "0";
    }
    const all18decimals = ethers.utils.formatEther(curds.mul(CHEDDDAR_TO_USD_RATE_FOR_1_CHEDDR));
    return all18decimals.substring(0, all18decimals.indexOf(".") + DISPLAY_DECIMALS_CHEDDR + 1);
};


export const convertCurdsToCheddr = (curds: BigNumber | undefined): string => {
    if (curds === undefined) {
        return "0.0";
    }
    const all18decimals = ethers.utils.formatEther(curds);
    return all18decimals.substring(0, all18decimals.indexOf(".") + DISPLAY_DECIMALS_CHEDDR + 1);
};
