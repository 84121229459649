import UAParser from "ua-parser-js";

export type TCurrentOS = "Android" | "iOS" | string;

export const PLATFORMS: { [key: string]: TCurrentOS } = {
    ANDROID: "Android",
    IOS: "iOS"
};

export const getCurrentOS = (): TCurrentOS => {
    return (new UAParser()).getOS().name as TCurrentOS;
};
