import { Api, ApiConfig } from "./CPCApi";
import { getEnvConfig } from "env/getEnvConfig";

//TS hackery to enable clean return types for Promises and in particular API calls
//inspired by https://timm.preetz.name/articles/typescript-async-function-return-value

// @FIXME: don't remove eslint-disable in front of ReturnedPromiseResolvedType
// 'cause it breaks half of the automatically detected types
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ReturnedPromiseResolvedType<T> = T extends (...args: any[]) => Promise<infer R> ? R : never;
export type Unarray<T> = T extends Array<infer U> ? U : T;

const apiConfig: ApiConfig = { baseUrl: getEnvConfig().apiBaseUrl };

// const simpleAuthConfig: ApiConfig = { baseUrl: env.simpleAuthBasePath };

const apiBuilder = (apiConfig: ApiConfig) => {
    return new Api(apiConfig);
};

export const api = apiBuilder(apiConfig);
