import { AuthFacadeContext, Web3AuthFacadeContext } from "cnp-frontend-core";
import { getEnvConfig } from "env/getEnvConfig";
import { BigNumber } from "ethers";
import { useAccountBalanceQuery, useAccountHistoryQuery, useWalletDetailsQuery } from "hooks";
import React, { Context, createContext, ReactElement, useContext, useMemo, useState } from "react";
import { UseQueryResult } from "react-query";
import { TAccountHistory, TBalanceDetails, TWalletDetails } from "services/api/accountService";

export type AccountContextParams = {
    balanceQueryResult?: UseQueryResult<TBalanceDetails | null>;
    accountHistoryQueryResult?: UseQueryResult<TAccountHistory | null>;
    walletDetailsQueryResult?: UseQueryResult<TWalletDetails | null>;

    userBalance?: TBalanceDetails | null;
    accountHistory?: TAccountHistory | null;
    walletDetails?: TWalletDetails | null;

    tokenCount?: number;
    balanceCurds?: BigNumber;
    currentStakingAvailable?: boolean | undefined;
    setCurrentStakingAvailable: (_: boolean | undefined) => void;
};

export const AccountContext: Context<AccountContextParams> =
    createContext({ setCurrentStakingAvailable: (_: boolean | undefined) => {} });

type TAccountContextProps = {
    children: ReactElement;
};

export function AccountContextProvider({ children }: TAccountContextProps): JSX.Element {
    const {
        account,
        isAuthorizedWithWeb3
    }: AuthFacadeContext = useContext(Web3AuthFacadeContext);

    const { defaultChainId } = getEnvConfig();

    const balanceQueryResult = useAccountBalanceQuery(account, defaultChainId, isAuthorizedWithWeb3);
    const accountHistoryQueryResult = useAccountHistoryQuery(account, defaultChainId, isAuthorizedWithWeb3);
    const walletDetailsQueryResult = useWalletDetailsQuery(account, defaultChainId, isAuthorizedWithWeb3);
    const [currentStakingAvailable, setCurrentStakingAvailable] = useState<boolean | undefined>(undefined);

    const userBalance = balanceQueryResult?.data;
    const accountHistory = accountHistoryQueryResult?.data;
    const walletDetails = walletDetailsQueryResult?.data;

    const tokenCount = (walletDetails?.assets?.length) || 0;

    const balanceCurds: BigNumber = useMemo(() => {
        if (!userBalance) {
            return BigNumber.from(0);
        }
        return BigNumber.from(userBalance.cheddar_curd_balance) || BigNumber.from(0);
    }, [userBalance]);

    const params: AccountContextParams = {
        balanceQueryResult,
        accountHistoryQueryResult,
        walletDetailsQueryResult,

        userBalance,
        accountHistory,
        walletDetails,

        tokenCount,
        balanceCurds,
        currentStakingAvailable,
        setCurrentStakingAvailable
    };

    return (
        <AccountContext.Provider value={params}>
            {children}
        </AccountContext.Provider>
    );
}
