import { useQuery, UseQueryResult } from "react-query";
import { getAssetDetailsByTokenId, TAssetDetails } from "../../../services/api/accountService";
import { TChainId } from "services/api/declarations";

export const getTokenDetailsAsync = async(
    tokenId: string | undefined,
    tokenSymbol: string | undefined,
    chainId: number | undefined
): Promise<TAssetDetails | null> => {
    if (!chainId || !tokenId || !tokenSymbol) {
        return null;
    }

    try {
        const tokenAsset = await getAssetDetailsByTokenId(String(chainId) as TChainId, tokenSymbol, tokenId);
        return tokenAsset;
    } catch (error) {
        throw error;
    }
};

export const useAssetDetailsForTokenId = (
    tokenId: string | undefined,
    tokenSymbol: string | undefined,
    chainId: number | undefined
): UseQueryResult<TAssetDetails | null> => {
    return useQuery<TAssetDetails | null>(
        ["assetDetails", chainId, tokenSymbol, tokenId],
        () => {
            if (tokenSymbol && tokenId && chainId) {
                return getTokenDetailsAsync(tokenId, tokenSymbol, chainId);
            }
            return null;
        },
        {
            enabled: Boolean(chainId && tokenId && tokenSymbol),
            refetchOnWindowFocus: false
        }
    );
};
