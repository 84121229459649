import { useState } from "react";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useForceUpdate = () => {
    const [, setValue] = useState(0); // integer state
    return () => {
        return setValue((value) => {
            return value + 1;
        });
    }; // update the state to force render
};
