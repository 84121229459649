import { InfoContext } from "contexts";
import { useContext } from "react";
import { useQuery, UseQueryResult } from "react-query";
import { extractErrorMessage } from "../../../utils";
import axios from "axios";
import { replaceIpfsHost } from "../../../utils/url";

export type TTokenIpfsDataAttribute = {
    trait_type: string;
    value: string;
};

export type TTokenIpfsData = {
    attributes: TTokenIpfsDataAttribute[];
    custom_fields: {
        compiler: string;
        date: number;
        dna: string;
        edition: number;
    };
    description: string;
    file_url?: string;
    image?: string;
    animation_url?: string;
    external_url?: string;
    name: string;
}

export const useTokenIpfsData = (tokenUri: string | undefined): UseQueryResult<TTokenIpfsData | null> => {
    const { showErrorMessage } = useContext(InfoContext);

    return useQuery<TTokenIpfsData | null>(
        ["tokenIpfsInfo", tokenUri],
        async() => {
            if (!tokenUri) {
                return null;
            }

            try {
                return await axios
                    .get(replaceIpfsHost(tokenUri))
                    .then((resp) => {
                        return resp.data;
                    })
                    .then((data) => {
                        return data || null as TTokenIpfsData | null;
                    });
            } catch (err) {
                console.error(err);
                showErrorMessage(extractErrorMessage(err));
                return null;
            }
        },
        {
            enabled: Boolean(tokenUri),
            refetchOnWindowFocus: false
        }
    );
};
