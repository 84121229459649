import { Loader } from "components/common/Loader/Loader";
import React, { Context, createContext, ReactElement, useState } from "react";
import { useHistory } from "react-router-dom";

export type SplashScreenParams = {
    redirectWithLoading: (message: string) => void;
    redirectWithoutLoading: (message: string) => void;
    showLoader: () => void;
    hideLoader: () => void;
};

export const SplashScreenContext: Context<SplashScreenParams>
    = createContext({
        redirectWithLoading: (_: string) => { },
        redirectWithoutLoading: (_: string) => { },
        showLoader: () => { },
        hideLoader: () => { }
    });

export function SplashScreenContextProvider({ children }: {
    children: ReactElement;
}): JSX.Element {
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    if (isLoading) {
        return <div><Loader /></div>;
    }

    const redirectWithLoading = (link: string) => {
        setIsLoading(true);
        setTimeout(() => {
            history.push(link || "/");
            setIsLoading(false);
        }, 3000);
    };

    const redirectWithoutLoading = (link: string) => {
        history.push(link || "/");
        setIsLoading(false);
    };

    const showLoader = () => {
        setIsLoading(true);
    };

    const hideLoader = () => {
        setIsLoading(false);
    };

    const params: SplashScreenParams = {
        redirectWithLoading,
        redirectWithoutLoading,
        showLoader,
        hideLoader
    };

    return (
        <SplashScreenContext.Provider value={params}>
            {children}
        </SplashScreenContext.Provider>
    );
}