import { useQuery, UseQueryResult } from "react-query";
import { getWalletDetails, TWalletDetails } from "../../../services/api/accountService";
import { extractErrorMessage } from "../../../utils";
import { useContext } from "react";
import { InfoContext } from "../../../contexts";
import { TChainId } from "services/api/declarations";

export const useWalletDetailsQuery = (
    account: string | null | undefined,
    chainId: number | undefined,
    isAuthorizedWithWeb3: boolean | undefined
): UseQueryResult<TWalletDetails | null> => {
    const { showErrorMessage } = useContext(InfoContext);

    return useQuery<TWalletDetails | null>(
        ["walletDetails", account, chainId, isAuthorizedWithWeb3],
        async() => {
            if (!chainId || !account || !isAuthorizedWithWeb3) {
                return null;
            }

            try {
                const detailsOfWallet = await getWalletDetails(String(chainId) as TChainId, account);
                const assetWithoutTestTokens = detailsOfWallet.assets
                    .filter((token) => {
                        return token.token_symbol && token.token_symbol !== "TEST";
                    });
                detailsOfWallet.assets = assetWithoutTestTokens;
                return detailsOfWallet;
            } catch (err) {
                showErrorMessage(extractErrorMessage(err));
                return null;
            }
        },
        {
            enabled: Boolean(chainId && account && isAuthorizedWithWeb3),
            refetchOnWindowFocus: false
        }
    );
};
