import React, { ReactNode } from "react";
import styles from "./Button.module.scss";
import classNames from "classnames";

type TButton = {
    type?: "submit" | "button";
    children: ReactNode;
    variant?: "primary" | "secondary" | "blank" | "default";
    view?: "square";
    isLoading?: boolean;
    disabled?: boolean;
    onClick?: () => void;
    lowercase?: boolean;
    selected?: boolean;
};

export const Button = (props: TButton): JSX.Element => {
    const {
        type="button",
        children,
        variant="primary",
        view,
        disabled,
        isLoading,
        onClick,
        lowercase,
        selected
    } = props;

    return (
        <div className={styles.ButtonWrapper}>
            <button
                onClick={onClick}
                type={type}
                className={classNames(
                    styles.Button,
                    styles[variant],
                    view && styles[view],
                    disabled && styles.disabled,
                    isLoading && styles.isLoading,
                    lowercase && styles.lowercase,
                    selected && styles.selected
                )}
                disabled={Boolean(disabled)}
            >
                {children}
            </button>
        </div>
    );
};