import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { TBaseRoute } from "./declarations";
import {
    AuthFacadeContext,
    AUTH_PROGRESS_STATUS,
    EthereumBrowserConnectorContext,
    RequiringEthereum,
    TAuthorizationProgressStatus,
    Web3AuthFacadeContext
} from "cnp-frontend-core";
import { Redirect, Route } from "react-router-dom";
import { RedirectToRootWithLoader } from "./RedirectToRootWithLoader";
import { Loader } from "../Loader/Loader";
import { getEnvConfig } from "env/getEnvConfig";
import { AccountContext, DetectHomePageContext } from "contexts";

const BlockchainGatewayNotInstalled = React.lazy(() => {
    return import("./BlockChainGateWay/BlockchainGatewayNotInstalled");
});
const BlockchainGatewayConnectWallet = React.lazy(() => {
    return import("./BlockChainGateWay/BlockchainGatewayConnectWallet");
});

type THomePageState =
    | "showLoader"
    | "hideLoader"
    | "goHome"
    | "404";

export const ProtectedRoute = (props: TBaseRoute): JSX.Element => {
    const { enableAutoRedirectToHome, component, ...restOfProps } = props;
    const env = getEnvConfig();

    const {
        authorizationStatus,
        isAuthorized,
        multipleEthereumWalletsEnabled
    } = useContext(EthereumBrowserConnectorContext);

    const {
        library,
        isEthereumBrowserSupported
    }: AuthFacadeContext = useContext(Web3AuthFacadeContext);

    const { getHomePageRoute, isMyToken } = useContext(DetectHomePageContext);

    const { walletDetails, currentStakingAvailable } = useContext(AccountContext);

    const conditions = useMemo(() => {
        return {
            isNotInstalled: !isEthereumBrowserSupported,
            isNotConnected: false,
            isFallback: !library
        };
    }, [
        isEthereumBrowserSupported,
        library
    ]);

    const isAuthActivating = useMemo(() => {
        return (authorizationStatus as TAuthorizationProgressStatus) === AUTH_PROGRESS_STATUS.ACTIVATION_IN_PROGRESS;
    }, [authorizationStatus]);

    const isAuthActivated = useMemo(() => {
        return (authorizationStatus as TAuthorizationProgressStatus) === AUTH_PROGRESS_STATUS.ACTIVATED;
    }, [authorizationStatus]);

    const [homePageState, setHomePageState] = useState<THomePageState>("showLoader");
    const [homePageRoute, setHomePageRoute] = useState<string | null>(null);

    useEffect(() => {
        let mounted = true;

        (async() => {
            if (mounted) {
                const homeRoute = await getHomePageRoute();
                setHomePageRoute(homeRoute);

                const currentRoute = window.location.pathname;

                if (homeRoute === null) {
                    setHomePageState("showLoader");
                }

                if (homeRoute === currentRoute) {
                    setHomePageState("hideLoader");
                }

                const isTokenInfoPage = (
                    homeRoute !== currentRoute &&
                    currentRoute.indexOf("/token/") !== -1 &&
                    walletDetails != null
                );
                if (isTokenInfoPage) {
                    const tokenIdInUrlString = currentRoute.replace("/token/", "");
                    if (!tokenIdInUrlString || isNaN(Number(tokenIdInUrlString))) {
                        setHomePageState("404");
                    } else {
                        setHomePageState("hideLoader");
                    }
                }

                const isStakingPage = (
                    homeRoute !== currentRoute &&
                    currentRoute.indexOf("/staking/") !== -1
                );
                if (isStakingPage) {
                    if (currentStakingAvailable) {
                        setHomePageState("goHome");
                    } else {
                        setHomePageState("hideLoader");
                    }
                }

                const isNotHomeOrStakingPage = (
                    homeRoute !== currentRoute &&
                    currentRoute.indexOf("/token/") === -1 &&
                    currentRoute.indexOf("/staking/") === -1
                );
                if (isNotHomeOrStakingPage) {
                    if (enableAutoRedirectToHome) {
                        setHomePageState("goHome");
                    } else {
                        setHomePageState("hideLoader");
                    }
                }
            }
        })();
        return () => {
            mounted = false;
        };
    }, [getHomePageRoute, walletDetails, enableAutoRedirectToHome]);

    const RequiringEthereumComponent = useCallback(() => {
        if (multipleEthereumWalletsEnabled) {
            return <>Multiple wallets</>;
        }

        if (isEthereumBrowserSupported && !isAuthorized) {
            return <RedirectToRootWithLoader />;
        }

        if (isAuthActivating) {
            return <Loader />;
        }

        if (isAuthActivated && homePageState === "404") {
            return <Redirect to="/404"/>;
        }

        if (isAuthActivated && homePageState === "showLoader") {
            return <Loader />;
        }

        if (isAuthActivated && homePageState === "goHome" && !homePageRoute) {
            return <Loader />;
        }

        if (isAuthActivated && homePageState === "goHome" && homePageRoute) {
            return (
                <Redirect to={homePageRoute}/>
            );
        }

        if (isAuthActivated && homePageState === "hideLoader") {
            return (
                <Route
                    {...restOfProps}
                    component={component}
                />
            );
        }

        return <RedirectToRootWithLoader />;
    }, [homePageState, homePageRoute]);

    return (
        <RequiringEthereum
            {...props}
            pageComponent={RequiringEthereumComponent}
            notInstalledComponent={BlockchainGatewayNotInstalled}
            connectWalletComponent={BlockchainGatewayConnectWallet}
            fallbackComponent={RedirectToRootWithLoader}
            conditions={conditions}
        />
    );
};
