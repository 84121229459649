/* eslint-disable @typescript-eslint/ban-ts-comment */
import { TAccountHistory } from "../services/api/accountService";
import { BigNumber, ethers } from "ethers";

const convertCHDRToHumanReadable = (valueCHDR: string) => {
    return Number(ethers.utils.formatEther(BigNumber.from(valueCHDR)));
};

// Calculation of available balance
export const transformAvailableBalanceChartValues = (accountHistory: TAccountHistory) => {
    const result: { timestamp: number, uv: number, pv: number }[] = [];

    if (accountHistory) {
        accountHistory.forEach(({ balance_available_curds, balance_available_curds_change, timestamp, type }, index, values) => {
            const pv = convertCHDRToHumanReadable(balance_available_curds);
            const uv = pv - convertCHDRToHumanReadable(balance_available_curds_change);


            if (type === "StakingStart" && values[index - 1]) {
                const pvPrev = convertCHDRToHumanReadable(values[index - 1].balance_available_curds);
                const uvPrev = pvPrev - convertCHDRToHumanReadable(values[index - 1].balance_available_curds_change);

                result.push({ timestamp, uv: uvPrev, pv: pvPrev });
            }

            // if "CoinTransfer", we need to duplicate the point with decreased Y value
            // so we could have a vertical line
            if (type === "CoinTransfer" && values[index]) {
                const pvPrev = convertCHDRToHumanReadable(values[index].balance_available_curds);
                const uvPrev = pvPrev - convertCHDRToHumanReadable(values[index].balance_available_curds_change);

                result.push({ timestamp, uv: uvPrev, pv: pvPrev });
            }

            result.push({ timestamp, uv, pv });
        });
    }

    return result
        .sort((firstHistory, secondHistory) => {
            if (firstHistory.timestamp < secondHistory.timestamp) return -1;
            if (firstHistory.timestamp > secondHistory.timestamp) return 1;
            return 0;
        });
};

export type TTransformedBalanceTable = {
    dateToName: number;
    name: string;
    stakingInfo: string;
    point: string;
};
export const transformAvailableBalanceTableValues = (accountHistory: TAccountHistory) => {
    const result: TTransformedBalanceTable[] = [];

    if (accountHistory) {
        accountHistory.forEach((history) => {
            if ((history.type as unknown) === "FaucetStarting") {
                const point = Number(
                    ethers.utils.formatEther(BigNumber.from(history.balance_available_curds))
                );

                result.push({
                    dateToName: transformUintDate(history.timestamp),
                    stakingInfo: history.type,
                    point: String(point.toFixed(6)),
                    name: ""
                });
                return;
            }

            const point = Number(
                ethers.utils.formatEther(BigNumber.from(history.balance_available_curds_change))
            );
            result.push({
                dateToName: transformUintDate(history.timestamp),
                stakingInfo: history.type,
                point: String(point.toFixed(6)),
                name: ""
            });
        });
    }

    return result
        .sort((firstHistory, secondHistory) => {
            if (firstHistory.dateToName < secondHistory.dateToName) return -1;
            if (firstHistory.dateToName > secondHistory.dateToName) return 1;
            return 0;
        })
        .map((history) => {
            history.name = transformTimestampToDate((new Date(history.dateToName)).getTime());
            return history;
        });
};

export const getHistoricalAccountBalance = (accountHistory: TAccountHistory) => {
    const { balance_available_curds } = accountHistory[accountHistory.length - 1];

    const all18decimals = ethers.utils.formatEther(BigNumber.from(balance_available_curds));

    return Number(all18decimals).toFixed(2);
};

// Calculation of staking balance
export const transformStakedBalanceChartValues = (accountHistory: TAccountHistory) => {
    return accountHistory && accountHistory.map(({ balance_staked_curds, balance_staked_curds_change, timestamp }) => {
        const uv = BigNumber.from(balance_staked_curds).div(BigNumber.from(10).pow(18)).toNumber();
        const pv = (BigNumber.from(balance_staked_curds).sub(balance_staked_curds_change)).div(BigNumber.from(10).pow(18)).toNumber();

        return { name: "", uv, pv };
    });
};

export const transformStakedBalanceTableValues = (accountHistory: TAccountHistory) => {
    return accountHistory && accountHistory.map(({ type, balance_staked_curds_change, timestamp }) => {
        const point = convertCHDRToHumanReadable(balance_staked_curds_change);

        return { name: transformTimestampToDate(transformUintDate(timestamp)), stakingInfo: type, point: String(point) };
    });
};


// TODO: implement functionality of total staked values
export const totalStaked = (accountHistory: TAccountHistory) => {};

export const transformUintDate = (timestamp: number): number => {
    return timestamp * 1000;
};

export const transformTimestampToDate = (timestamp: number): string => {
    const date = new Date(timestamp);

    return `${
        convertNumberToFixedValues(date.getDate())
    }.${
        convertNumberToFixedValues(date.getMonth() + 1) // January starts with 0
    }.${
        date.getFullYear()
    }`;
};


const convertNumberToFixedValues = (value: number) => {
    return value > 9 ? value : `0${value}`;
};
