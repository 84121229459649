import { EnvContextCoreParams } from "cnp-frontend-core";

const testsubdomains = ["ccc.customer.capacity.at", "testnft.cpc.com"];
// const testvmdomains = [];
const prodsubdomains = ["nft.cpc.com"];
const localtesting = ["localhost"];

export function isInList(hostname: string, hostnames: string[]): boolean {
    return hostnames.includes(hostname);
}

// using the "TEST" token symbol in requests
// could throw an error on the backend side
type TTokenSymbol = "TEST" | "CCC" | "CPC";

type TSpecificFeatures = {
    tokenSymbol: TTokenSymbol;
    ipfsHost: string;
    sleepingTokenImage: string;
    apiBaseUrl: string;
    frontendHost: string;
    openSeaNftCollection: string;
};

export type TCpcContextParams = EnvContextCoreParams & TSpecificFeatures;

export const getEnvConfig: () => TCpcContextParams = () => {
    const prodRamp = {
        url: "",
        hostApiKey: "",
        currency: ""
    };

    // const { hostname } = window.location;

    /**
     * @FIXME:
     * @TODO:
     * https://docs.polygon.technology/docs/develop/network-details/network/
     *
     * Polygon network (former: Matic) has the following RPC settings:
     *
     * I) prod network
     * 1) RPC URL https://rpc-mainnet.matic.network/
     * 2) Symbol: MATIC
     * 3) ChainId: 137
     *
     * II) test network
     * Mumbai-Testnet https://docs.polygon.technology/docs/develop/network-details/network/
     * 1) ChainId 80001
     * 2) RPC URL: 'https://rpc-mumbai.matic.today'
     *
     * block explorer: https://polygonscan.com/
     */

    const localEnv: TCpcContextParams = {
        apiBaseUrl: "https://testnft.cpc.com/api",
        targetProject: "cpc",
        layer1NetworkId: 3, // @FIXME: need to change the chainid to Polygon
        layer2NetworkId: 5,
        baseEnsL1: "", // @FIXME: need to add the actual one
        baseEnsL2: "", // @FIXME: need to add the actual one
        ramp: prodRamp,
        defaultChainId: 5, // @FIXME: need to change the chainid to Polygon for Prod
        frontendHost: "http://localhost:3000",
        featureConfig: {
            connectorFeatures: {
                restoreSessionAutomatically: true,
                authMode: "web3"
            },
            web3Providers: {
                walletConnect: false,
                inBrowserWallet: false
            },
            oauth: {
                google: false,
                apple: false,
                simpleEmail: false
            }
        },
        tokenSymbol: "CCC",
        ipfsHost: "ipfs.capacity.at", // "metananos.mypinata.cloud"
        sleepingTokenImage: "https://ipfs.capacity.at/ipfs/QmTYVgyzZPXqxxqeEPaFENUoy2wPfzEdtAmGVaoGR8Tovx",
        openSeaNftCollection: "https://opensea.io/collection/cpc-apothecary"
    };

    const testEnv: TCpcContextParams = {
        apiBaseUrl: "https://testnft.cpc.com/api",
        targetProject: "cpc",
        layer1NetworkId: 3, // @FIXME: need to change the chainid to Polygon
        layer2NetworkId: 5,
        baseEnsL1: "", // @FIXME: need to add the actual one
        baseEnsL2: "", // @FIXME: need to add the actual one
        ramp: prodRamp,
        defaultChainId: 5, // @FIXME: need to change the chainid to Polygon for Prod
        frontendHost: "https://testnft.cpc.com",
        featureConfig: {
            connectorFeatures: {
                restoreSessionAutomatically: true,
                authMode: "web3"
            },
            web3Providers: {
                walletConnect: false,
                inBrowserWallet: false
            },
            oauth: {
                google: false,
                apple: false,
                simpleEmail: false
            }
        },
        tokenSymbol: "CCC",
        ipfsHost: "ipfs.capacity.at", // "metananos.mypinata.cloud"
        sleepingTokenImage: "https://ipfs.capacity.at/ipfs/QmTYVgyzZPXqxxqeEPaFENUoy2wPfzEdtAmGVaoGR8Tovx",
        openSeaNftCollection: "https://opensea.io/collection/cpc-apothecary"
    };

    const prodEnv: TCpcContextParams = {
        apiBaseUrl: "https://nft.cpc.com/api",
        targetProject: "cpc",
        layer1NetworkId: 137,
        layer2NetworkId: 137,
        baseEnsL1: "",
        baseEnsL2: "",
        ramp: prodRamp,
        defaultChainId: 137,
        frontendHost: "https://nft.cpc.com",
        featureConfig: {
            connectorFeatures: {
                restoreSessionAutomatically: true,
                authMode: "web3"
            },
            web3Providers: {
                walletConnect: false,
                inBrowserWallet: false
            },
            oauth: {
                google: false,
                apple: false,
                simpleEmail: false
            }
        },
        tokenSymbol: "CPC",
        ipfsHost: "ipfs.capacity.at", // "metananos.mypinata.cloud"
        sleepingTokenImage: "https://ipfs.capacity.at/ipfs/QmTYVgyzZPXqxxqeEPaFENUoy2wPfzEdtAmGVaoGR8Tovx",
        openSeaNftCollection: "https://opensea.io/collection/cpc-apothecary"
    };

    if (isInList(window.location.hostname, testsubdomains)) {
        return testEnv;
    } else if (isInList(window.location.hostname, prodsubdomains)) {
        return prodEnv;
    } else if (isInList(window.location.hostname, localtesting)) {
        return localEnv;
    }
    throw new Error("Environment configuration is not set up!");
};
